import Config from '../../../../shared/Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch client sessions, please try again!',
};

export const logoutUser = async () => {
  const response = await client.doPost(Config.api.auth.logout, {});
  if (response.success) {
    return response.success;
  }

  throw new Error(response.error?.message || errorMessages.generic);
};
