import { toast } from 'sonner';
import { useMutation } from '../../../utilities/hooks/useFetch';
import { logoutUser } from '../../shared/service/API/auth';

interface UseAuthProps {
  onSuccess: () => void;
}

function useUserAuthentication({ onSuccess }: UseAuthProps) {

  const { mutate: logout, isLoading: isLogoutInProgress } = useMutation(
    logoutUser,
    {
      onSuccess,
      onError: () => {
        toast.error('Unable to logout. please try again later');
      },
    },
  );

  return {
    logout,
    isLogoutInProgress,
  };
}

export default useUserAuthentication;
