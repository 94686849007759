/* eslint-disable */
import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../../assets/close.svg';
import { arrayRange } from '../../../utilities/common/Array';
import { useFeedbackDetails } from './hooks/useFeedbackDetails';
import { InteractionType } from '../../../shared/constant/Common';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';

interface IScaleData {
  rating: number | null;
  hoveredValue: number | null;
  show: boolean;
  showConfirmation: boolean;
}

interface IRatingScale {
  minScaleRange?: number;
  maxScaleRange?: number;
  title: string;
  leftLegendText: string;
  rightLegendText: string;
  onSkip?: () => void;
  onSubmit?: (rating: number) => void;
  confirmationMessage: string;
}

function RatingScale({
  minScaleRange = 0,
  maxScaleRange = 10,
  title,
  leftLegendText,
  rightLegendText,
  onSkip,
  onSubmit,
  confirmationMessage,
}: Readonly<IRatingScale>) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { addScore, submissionStatus, isSubmissionStatusLoading } =
    useFeedbackDetails();

  const [scaleData, setScaleData] = React.useState<IScaleData>({
    rating: null,
    hoveredValue: null,
    show: false,
    showConfirmation: false,
  });

  React.useEffect(() => {
    if (
      !isSubmissionStatusLoading &&
      submissionStatus &&
      !scaleData.showConfirmation
    ) {
      setScaleData((prev) => ({
        ...prev,
        show: !submissionStatus?.submitted,
      }));
      if (!submissionStatus.submitted) {
        track(EventNames.providerNps, {
          eventAction: EventActions.load,
          eventCategory: EventCategories.providerNps,
          eventLabel: 'provider_nps',
        });
      }
    }
  }, [submissionStatus, isSubmissionStatusLoading]);

  const handleCloseCTA = () => {
    if (!scaleData.showConfirmation) {
      onSkip?.();
      addScore({
        interaction: InteractionType.Skipped,
      });
      track(EventNames.providerNps, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.close,
        eventLabel: 'close',
      });
    }
    setScaleData({
      rating: null,
      hoveredValue: null,
      show: false,
      showConfirmation: false,
    });
  };

  const handleRatingClick = (score: number) => {
    setScaleData((prev) => ({
      ...prev,
      rating: score,
    }));
  };

  const handleSubmit = () => {
    if (scaleData.rating !== null) {
      setScaleData((prev) => ({
        ...prev,
        rating: scaleData.rating,
        showConfirmation: true,
      }));
      onSubmit?.(scaleData.rating);
      addScore({
        score: scaleData.rating,
        interaction: InteractionType.Completed,
      });
      track(EventNames.providerNps, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.npsSubmitted,
        eventLabel: 'nps_submitted',
        eventValue: scaleData.rating,
      });
    }
  };
  return (
    <AnimatePresence>
      {scaleData.show ? (
        <motion.dialog
          className="w-full z-[1000000] box-border fixed bottom-0 inset-x-0 p-[10px] border-t text-gray-700 bg-gray-25 grid grid-col-12 gap-y-0.5 md: p-5 justify-center"
          aria-labelledby="rating-scale-title"
          layout
          initial={{ height: 0 }}
          animate={{ height: '165px', transition: { duration: 1 } }}
          exit={{ height: 0, transition: { duration: 1 } }}
          key="rating-scale"
        >
          <Close
            className="absolute right-5 cursor-pointer md: h-2.5 md: w-2.5 md: right-2.5 top-2.5"
            onClick={handleCloseCTA}
            aria-label="Close rating scale"
          />
          {!scaleData.showConfirmation ? (
            <>
              <section className="flex items-center justify-center py-3 md:py-2">
                <p
                  id="rating-scale-title"
                  className="text-xs font-medium md:text-sm "
                >
                  {title}
                </p>
              </section>
              <section
                className="min-w-[300px] my-0 mx-auto flex flex-col gap-3"
                aria-describedby="rating-scale-description"
              >
                <section
                  className="my-0 mx-auto flex justify-center"
                  aria-label="Rating scale"
                >
                  {arrayRange(minScaleRange, maxScaleRange).map(
                    (item, index) => (
                      <motion.button
                        key={`${index + item}`}
                        className={`h-[24px] w-[24px] my-0 mx-px rounded-full border md: text-xs md:h-8 md:w-8 md:mx-1 ${
                          (scaleData.rating !== null &&
                            scaleData.rating >= item) ||
                          (scaleData.hoveredValue !== null &&
                            scaleData.hoveredValue >= item)
                            ? 'border-primary-100 text-primary-600 bg-primary-50'
                            : 'bg-white'
                        }`}
                        onClick={() => handleRatingClick(item)}
                        whileHover={{
                          scale: 1.25,
                        }}
                        whileTap={{ scale: 0.975 }}
                        type="button"
                        onHoverStart={() =>
                          setScaleData((prev) => ({
                            ...prev,
                            hoveredValue: item,
                          }))
                        }
                        onHoverEnd={() =>
                          setScaleData((prev) => ({
                            ...prev,
                            hoveredValue: null,
                          }))
                        }
                        aria-label={`Rate ${item}`}
                        tabIndex={0}
                      >
                        {item}
                      </motion.button>
                    ),
                  )}
                </section>
                <section
                  className="flex text-xs mt-[-5px]"
                  id="rating-scale-description"
                >
                  <section className="flex-1 text-left">
                    {leftLegendText}
                  </section>
                  <section className="flex-1 text-right">
                    {rightLegendText}
                  </section>
                </section>
                <motion.button
                  whileHover={{ scale: 1.025 }}
                  whileTap={{ scale: 0.975 }}
                  type="button"
                  className="w-[280px] text-sm h-8 my-0.5 mx-auto bg-primary-600 hover:bg-primary-700 active:focus:bg-primary-800 text-white disabled:bg-primary-200 rounded md: w-full"
                  onClick={handleSubmit}
                  aria-label="Submit rating"
                  disabled={scaleData.rating === null}
                >
                  {t('SUBMIT')}
                </motion.button>
              </section>
            </>
          ) : (
            <motion.section className="flex items-center justify-center" layout>
              <p id="rating-scale-title" className="md: text-sm">
                {confirmationMessage}
              </p>
            </motion.section>
          )}
        </motion.dialog>
      ) : null}
    </AnimatePresence>
  );
}

export default RatingScale;
