import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal';
import NoDataFound from '../../../../components/NoDataFound';
import { ReactComponent as ConfusedImage } from '../../../../../assets/SessionNoShowAndEndImage.svg';
import './MarkNoShowModal.scss';

export function MarkNoShowModal({
  open,
  handleClose,
  handleSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const { t } = useTranslation();

  const [noShowButtonTimer, setNoShowButtonTimer] = React.useState<number>(0);

  React.useEffect(() => {
    if (open) {
      setNoShowButtonTimer(5);
      const timer = setInterval(() => {
        setNoShowButtonTimer((time) => {
          if (time === 0) {
            clearInterval(timer);
            return 0;
          }
          return time - 1;
        });
      }, 1000);
    }
  }, [open]);

  return (
    <Modal open={open} handleClose={() => handleClose()}>
      <NoDataFound
        title={t('ONSITE_MARK_NO_SHOW_POPUP_TITLE')}
        description={t('ONSITE_MARK_NO_SHOW_POPUP_BODY')}
        Icon={ConfusedImage}
      />
      <div className="flex flex-col items-center gap-3 mt-8">
        <div className="text-sm text-gray-700 mb-5">
          {t('ONSITE_MARK_NO_SHOW_POPUP_FOOTNOTE')}
        </div>

        <hr className="w-full mb-2" />
        <button
          type="button"
          className={`mark-noshow-modal-progress-button ${
            noShowButtonTimer === 0 ? '' : 'progress'
          }`}
          onClick={() => handleSubmit()}
          disabled={noShowButtonTimer !== 0}
        >
          <div className="noshow-text">
            {t('ONSITE_MARK_NO_SHOW_CTA', {
              count: noShowButtonTimer < 1 ? undefined : noShowButtonTimer,
            })}
          </div>
        </button>
      </div>
    </Modal>
  );
}
