import NetworkClient from '../../../v2/shared/service/Network';
import Config from '../../Config';
import { addItemToLocalStorage } from '../../../utilities/common/Storage';
import { StorageItems } from '../../constant/App';
import { getIrisAccessToken } from '../../service/API/iris';
import { setUserId } from '../../../utilities/monitoring';

const client = new NetworkClient();

export const authenticateUser = (email: string, password: string) => () => {
  const requestBody = { email, password };
  return client
    .doPost(Config.api.auth.login, requestBody)
    .then((response) => {
      console.log(response);
      if (
        response.data &&
        response.success &&
        response.data.addOns.isProvider
      ) {
        addItemToLocalStorage(StorageItems.USER_INFO, response.data);
        getIrisAccessToken(response.data);
        setUserId(response.data.id);
        return true;
      }
      return response.details || response.error?.message; // TODO might wanna centralize
    })
    .catch((error) => {
      console.error('Error occurred while login - ', error);
    });
};

export const requestMagicCode = async (email: string) => {
  const response = await client.doPost(Config.api.auth.otpRequest, { email });
  if (response && response.success) {
    return response?.data;
  }
  throw new Error(response?.error?.message);
};

export const resetPassword = async ({
  email,
  otp,
  password,
}: {
  email: string;
  otp: string;
  password: string;
}) => {
  const response = await client.doPost(Config.api.auth.resetPassword, {
    code: otp,
    password,
    email,
  });
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.error?.message);
};
