import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import {
  IFollowUpInSessionsForm,
  IFollowUpSessionsForm,
  IFollowUpSessionsFormSections,
  IWarningModalContent,
} from './FollowUpSession.type';
import Heading from './Heading';
import RiskAssessment from './components/RiskAssessments';
import ReferCarer from './ReferCarer';
import {
  CaseNoteFormType,
  CaseNoteQuestionTypes,
  RiskAssesment,
} from '../../../shared/constant/CoachingCaseNotes';
import TextArea from '../../components/TextEditor';
import Popup from '../../components/Popup';
import FollowUpSessionCaseNotesControls from './FollowUpSessionCaseNotes.controls';
import { MAX_CHAR_LIMIT_TEXTAREA } from '../../../shared/constant/generics';
import PresentingIssues from './components/PresentingIssues';
import NoSaveWarningModal from './components/NoSaveWarningModal';
import useCaseNotesDetails, {
  getAllCaseNoteOptions,
} from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import CaseNoteFormHeader from '../../components/CaseNoteFormHeader';
import SupportedQuestion from './components/RiskAssessments/SupportedQuestions';
import { ICaseNoteQuestionResp } from '../CaseNotesV2/types';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';
import { ICaseNoteResp } from '../../../shared/types/CaseNotes';
import SideDrawer from '../../components/SideDrawer';
import './FollowupSessions.scss';
import { externalUrls } from '../../../shared/Config';
import SideDrawerContext, {
  ISideDrawerContext,
} from '../../../shared/context/SideDrawerContext';
import SendSOSPopup from '../SendSOSPopup';
import { IClientSummary } from '../../../shared/types/response/provider';
import decodeCode from '../../../utilities/common/Encryption';
import GuidelineModal from '../../../v2/view/app-components/GuidelineModal';

const defaultValues = {
  riskAssessment: {},
  sessionNotes: '',
  primaryIssue: {},
  secondaryIssue: {},
  suicidalAssessment: {},
};

interface FollowUPSessionCaseNotes {
  clientId?: string;
  caseNotesData: ReturnType<typeof useCaseNotesDetails>;
  caseNotesQuestionOptions: ReturnType<typeof getAllCaseNoteOptions>;
  show: boolean;
  meetingId: string;
  onClose?: () => void;
  selectedClientData: IClientSummary;
}

function FollowupSessionOngoing({
  show,
  clientId,
  caseNotesData,
  caseNotesQuestionOptions,
  meetingId,
  onClose,
  selectedClientData,
}: Readonly<FollowUPSessionCaseNotes>) {
  const { track, trackDuration } = useTracking();
  const { t } = useTranslation();

  const {
    newCaseNoteQuestions,
    newCaseNoteAnswers,
    saveNewcaseNotes: saveCaseNotes,
  } = caseNotesData;
  const {
    isOptionsLoading,
    riskCategoriesOptions,
    suicidalAssessmentsOptions: supportedQuestionOptions,
    issuesOptions: presentingIssueOptions,
  } = caseNotesQuestionOptions;
  const [initialCaseNoteAnswer, setInitialCaseNoteAnswer] = React.useState<
    Partial<IFollowUpSessionsForm>
  >({});
  const [showWarningModal, setShowWarningModal] = React.useState<string>('');
  const [showSoSModal, setShowSoSModal] = React.useState<boolean>(false);
  const [sosModalStatus, setSosModalStatus] = React.useState({
    showSOSConfirmation: false,
  });
  const { watch, setValue, getValues, handleSubmit, reset } = useForm<
    Partial<IFollowUpSessionsForm>
  >({ defaultValues });

  const getInitialData = () =>
    FollowUpSessionCaseNotesControls.getInitialData<IFollowUpInSessionsForm>({
      caseNoteAnswerData: newCaseNoteAnswers || ({} as ICaseNoteResp),
      caseNoteQuestionData:
        newCaseNoteQuestions || ({} as ICaseNoteQuestionResp[]),
      riskCategoriesOption: riskCategoriesOptions,
      primaryPresentingOptions: presentingIssueOptions,
      suicidalAssessmentOptions: supportedQuestionOptions,
      fromTypes: [
        CaseNoteFormType.V7.toString(),
        CaseNoteFormType.V5.toString(),
      ],
    });
  React.useEffect(() => {
    if (newCaseNoteAnswers && newCaseNoteQuestions && !isOptionsLoading) {
      const savedFormData = getInitialData();
      setInitialCaseNoteAnswer(() => savedFormData);
      reset(savedFormData);
    }
  }, [newCaseNoteAnswers, newCaseNoteQuestions, isOptionsLoading]);
  const hasDataModified = (formValue: any) =>
    JSON.stringify(formValue) === JSON.stringify(getValues());
  const handleClose = () => {
    if (
      hasDataModified(defaultValues) ||
      hasDataModified(initialCaseNoteAnswer)
    ) {
      onClose?.();
    } else {
      setShowWarningModal('goBack');
    }
  };
  const warningContent: IWarningModalContent = React.useMemo(
    () => ({
      noData: t('NO_DATA_PROMPT'),
      goBack: t('WARNING_DESC'),
    }),
    [],
  );

  const handleDuration = () => {
    trackDuration(EventCategories.viewFullSessionCaseNotes);
  };

  const trackQuestionId: IFollowUpSessionsFormSections['trackOnBlur'] = (
    questionId,
  ) => {
    track(EventNames.viewFullSessionCaseNotes, {
      eventLabel: questionId?.toString(),
      eventValue: 'clinical',
      eventAction: EventActions.click,
      eventCategory: EventCategories.questionId,
      featureVersion: 'v1',
    });
  };

  const [showExtraOptionsForHC, showExtraOptionsForHNC, showExtraOptionForMed] =
    React.useMemo(() => {
      let extraOpt: boolean[] = [false, false, false];
      extraOpt = [
        RiskAssesment.HighRiskCritical.toString() ===
          watch('riskAssessment')?.value?.toString(),
        RiskAssesment.HighRiskNonCritical.toString() ===
          watch('riskAssessment')?.value?.toString(),
        RiskAssesment.MediumRisk.toString() ===
          watch('riskAssessment')?.value?.toString(),
      ];
      return extraOpt;
    }, [watch('riskAssessment')]);
  const caseNotesQuestionsData = React.useMemo(
    () =>
      FollowUpSessionCaseNotesControls.getCaseNotesQuestions<ICaseNoteQuestionResp>(
        newCaseNoteQuestions,
      ),
    [newCaseNoteQuestions],
  );

  const handleShowRefer = () => {
    window.open(externalUrls.referral.typeformLink, '_blank', 'noreferrer');
  };

  const handleSaveButton = () => {
    const payload = FollowUpSessionCaseNotesControls.saveCaseNoteData(
      getValues(),
      meetingId as string,
      decodeCode(clientId as string).toString(),
      caseNotesQuestionsData,
    );
    if (JSON.stringify(payload) !== '{}') {
      saveCaseNotes(payload);
    }
  };

  const handleCancelWarningModal = () => {
    setShowWarningModal('');
  };

  const handleConfirmWarningModal = () => {
    setShowWarningModal('');
    onClose?.();
  };
  const contextValue = React.useMemo(() => ({}), []);
  const onSOSBtnClick = () => {
    track(EventNames.sosHelpLine, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.sosHelpLine,
      eventLabel: 'sos_helpline',
      featureVersion: 'v1',
      eventSource: EventSource.session,
    });
    setSosModalStatus((prev) => ({ ...prev, showSOSConfirmation: true }));
  };
  const onSOSClose = (isSOSSuccessful: boolean) => {
    if (isSOSSuccessful) {
      toast.success(t('SOS_NEW_SUCCESS_PROMPT_SINGLE'));
    }
    setSosModalStatus((prev) => ({
      ...prev,
      showSOSConfirmation: false,
    }));
    track(EventNames.sosHelpLine, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.close,
      featureVersion: 'v1',
      eventLabel: 'close',
      eventSource: EventSource.session,
    });
  };
  return (
    <SideDrawerContext.Provider value={contextValue as ISideDrawerContext}>
      <SideDrawer
        show={show as boolean}
        onCloseHandler={handleClose}
        className="in-session-followup"
      >
        <div className="video-conferencing-followup">
          <section>
            <CaseNoteFormHeader
              headerText={t('SESSION', {
                count: newCaseNoteAnswers?.meeting?.count,
              })}
              diplayDate=""
            />
          </section>
          <form
            onSubmit={handleSubmit(handleSaveButton)}
            key="followupSessionCaseNotesForm"
          >
            <section
              className=" section riskAssessment-container"
              key="followUpSession-1"
            >
              <RiskAssessment
                watch={watch}
                trackOnBlur={trackQuestionId}
                trackOnFocus={handleDuration}
                setValue={setValue as IFollowUpSessionsFormSections['setValue']}
                question={
                  caseNotesQuestionsData[CaseNoteQuestionTypes.RiskAssesment]
                }
                riskAssessmentOptions={FollowUpSessionCaseNotesControls.getFormattedOptions(
                  riskCategoriesOptions,
                )}
                hideTag
              />
              {newCaseNoteAnswers?.form.toString() === CaseNoteFormType.V5 &&
                (showExtraOptionForMed || showExtraOptionsForHNC) && (
                  <ReferCarer
                    onClick={handleShowRefer}
                    from="ongoing-coaching"
                    showRedirectArrow={false}
                    description={t('REFERRAL_TEXT')}
                    btnLabel={`${t('INFORM_CTA', { arrowcode: '' })}`}
                  />
                )}
              {(showExtraOptionsForHC || showExtraOptionsForHNC) && (
                <SupportedQuestion
                  watch={watch}
                  showRefer={showExtraOptionsForHC}
                  handleRefer={() => setShowSoSModal(true)}
                  setValue={
                    setValue as IFollowUpSessionsFormSections['setValue']
                  }
                  supportedFieldsOpt={FollowUpSessionCaseNotesControls.getFormattedOptions(
                    supportedQuestionOptions,
                  )}
                  trackOnBlur={trackQuestionId}
                  trackOnFocus={handleDuration}
                  showSOSSUpport={
                    showExtraOptionsForHC || showExtraOptionsForHNC
                  }
                  handleSOSClick={onSOSBtnClick}
                />
              )}
              {sosModalStatus.showSOSConfirmation && (
                <SendSOSPopup
                  onCancel={(isSOSSuccessful) => onSOSClose(isSOSSuccessful)}
                  clientData={{
                    ...selectedClientData,
                    userId: decodeCode(selectedClientData.userId.toString()),
                  }}
                  showCloseIcon={false}
                  trackingData={{
                    source: EventSource.session,
                    eventValue: meetingId,
                  }}
                />
              )}
              <section
                className="section presentingIssueContainer"
                key="followUpSession-2"
              >
                <PresentingIssues
                  meetingId={meetingId}
                  trackOnBlur={trackQuestionId}
                  trackOnFocus={handleDuration}
                  watch={watch}
                  setValue={
                    setValue as IFollowUpSessionsFormSections['setValue']
                  }
                  primaryOptions={presentingIssueOptions}
                  secondaryOptions={presentingIssueOptions}
                  primaryQuestion={
                    caseNotesQuestionsData[CaseNoteQuestionTypes.PrimaryGoal]
                  }
                  secondaryQuestion={
                    caseNotesQuestionsData[CaseNoteQuestionTypes.SecondaryGoal]
                  }
                  hideTag
                />
              </section>

              <section className="section observation" key="followUpSession-3">
                <Heading
                  headerText={
                    caseNotesQuestionsData[CaseNoteQuestionTypes.SessionNotes]
                      ?.question
                  }
                  toolTipID="2"
                  hideTooltipChips
                />
                <TextArea
                  parametersValue={[]}
                  defaultTemplate={watch('sessionNotes') as string}
                  onTextValueChange={(value) => {
                    setValue('sessionNotes', value);
                  }}
                  maxCharacterLimit={MAX_CHAR_LIMIT_TEXTAREA}
                  placeHolderText={t('CASE_NOTE_SESSION_PLACEHOLDER')}
                  showMaxCharText={false}
                />
              </section>

              <section className="saveButton">
                <motion.input
                  whileHover={{ scale: 1.025 }}
                  whileTap={{ scale: 0.975 }}
                  className="submit-button"
                  value={t('SAVE_CTA')}
                  type="submit"
                />
              </section>
            </section>
          </form>
          <GuidelineModal
          title={t('INTELLECT_SOS_GUIDELINES_HEADER')}
          handleClose={() => setShowSoSModal(false)}
          open={showSoSModal}
        />
        </div>
      </SideDrawer>
      <Popup
        withBackdrop
        show={['noData', 'goBack'].includes(showWarningModal)}
        useDefaultPadding={false}
        onClose={() => setShowWarningModal('')}
      >
        <NoSaveWarningModal
          header={t('IMPORTANT')}
          subHeader={
            warningContent[showWarningModal as keyof IWarningModalContent]
          }
          onCancel={handleCancelWarningModal}
          onConfirm={handleConfirmWarningModal}
          cancelBtnText={t('CANCEL_CTA')}
          confirmBtnText={t('CONFIRM_CTA')}
        />
      </Popup>
    </SideDrawerContext.Provider>
  );
}

export default FollowupSessionOngoing;
