/* eslint-disable */
import * as React from 'react';
import * as Sentry from '@sentry/react';
import {
  useVideoCall,
  HomeScreen,
  Haircheck,
  VideoTile,
  Tray,
  VideoStates,
  NetworkStatus,
} from 'frame';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import './VideoCallRoom.scss';
import { useTranslation } from 'react-i18next';
import LeaveRoomConfirmPopup from '../../app-components/LeaveRoomConfirmPopup/LeaveRoomConfirmPopup';
import useCaseNotesDetails, {
  getAllCaseNoteOptions,
  getCaseNoteFormType,
} from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import {
  CaseNoteFormType,
  CaseNoteQuestionTypes,
} from '../../../shared/constant/CoachingCaseNotes';
import { encodeCode } from '../../../utilities/common/Encryption';
import { IMeetingStatus } from '../../../shared/types/response/provider';
import useLayout from '../../../utilities/hooks/useLayout';
import PrimaryIssueModal from '../../app-components/VideoRoom/components';
import { ICasenoteQuestionData } from '../../app-components/CaseNotesV2/types';
import useAuth from '../../../utilities/hooks/useAuth';
import Loader from '../../components/Loader';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import { toast } from 'sonner';
const VideoCallRoom = ({
  token,
  channel,
  locationRef,
  meeting,
  isFirstMeeting,
}: {
  token?: string;
  channel: 'dailyco' | 'zoom' | 'twilio';
  locationRef?: any;
  meeting: IMeetingStatus;
  isFirstMeeting?: boolean;
}) => {
  const vref = React.useRef<any>(null);
  const rvref = React.useRef<any>(null);
  const screenvref = React.useRef<any>(null);
  const { user } = useAuth();
  const { track } = useTracking();

  function getNetworkStatus(status: NetworkStatus){
    if(status === 'low'){
      toast.error('Your connection is unstable—video and audio may lag')
    }
  }
  function getErrorInfo(error: string){
    track(EventNames.errorJoiningSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.meetingError,
      eventLabel: error,
      clientId: meeting?.data?.clientId,
      featureVersion: 'v1',
      eventSource: 'provider_web',
      meetingId: `${channel}: ${locationRef}`,
    });
    Sentry.captureException(error);
  }
  const {
    meetingState,
    useMediaDevices,
    remoteParticipant,
    toggleAudio,
    toggleVideo,
    isAudioEnabled,
    isVideoEnabled,
    leaveCall,
    joinCall,
    confirmLeaveCall,
    startCall,
    error,
    retry,
    localParticipantDetails,
    useHairCheck,
    toggleScreenShare,
    screenShare,
    isScreenShareSupported,
    continueRoom,
  } = useVideoCall({
    clientType: channel,
    roomName: locationRef,
    token,
    onError: getErrorInfo,
    onNetworkChange: getNetworkStatus
  });
  const { getDevices, setLocalParticipantDetail, devices } = useMediaDevices();
  const { t } = useTranslation();
  const { updateChatWindowSettings } = useLayout();
  const [primaryIssueModal, setPrimaryIssueModal] = React.useState(false);

  React.useEffect(() => {
    if (meetingState === VideoStates.INITIALIZING) {
      setLocalParticipantDetail({ name: user?.name?.trim() });
      getDevices();
    }
  }, [meetingState]);

  const caseNotesData = useCaseNotesDetails({
    meetingId: meeting?.data?.meetingId as string,
  });

  const {
    refetchPrimaryIssueStatus,
    primaryIssueStatusLoading,
    saveNewcaseNotes,
    newCaseNoteQuestions,
    isQuestionaireLoading,
  } = useCaseNotesDetails({
    meetingId: meeting?.data?.meetingId as string,
    afterAddingCaseNotes: () => {
      leaveCall();
      setPrimaryIssueModal(false);
    },
  });

  const caseNoteQuestionsData = React.useMemo(() => {
    const questions: ICasenoteQuestionData = {};
    newCaseNoteQuestions?.forEach((question: any) => {
      questions[question.type] = {
        question: question.question,
        id: question.id,
      };
    });
    return questions;
  }, [isQuestionaireLoading, newCaseNoteQuestions]);

  const followupCaseNotesOptions = getAllCaseNoteOptions(
    caseNotesData?.newCaseNoteAnswers?.form as string,
    meeting?.data?.providerRole as string,
    meeting?.data?.meetingId
  );

  const { issuesOptions: presentingIssueOptions } = followupCaseNotesOptions;
  const { fetchCasenoteFormType } = getCaseNoteFormType((data) => {
    if (
      data.form === CaseNoteFormType.V3 ||
      data.form === CaseNoteFormType.V4
    ) {
      window.location.replace(
        `/carenotes/${meeting?.data?.providerRole}/${encodeCode(
          Number(meeting?.data?.clientId),
        )}/${meeting?.data?.meetingId}`,
      );
    } else if (data.form === CaseNoteFormType.V1) {
      window.location.replace(
        `/coachingnotes/${meeting?.data?.providerRole}/${encodeCode(
          Number(meeting?.data?.clientId),
        )}/${meeting?.data?.meetingId}`,
      );
    } else {
      window.location.replace('/sessions');
    }
    confirmLeaveCall();
  });

  function onJoinClick() {
    joinCall(vref, rvref, screenvref);
    track(EventNames.inSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.joinCall,
      eventLabel: 'join_call',
      featureVersion: 'v1',
    });
  }

  function onToggleAudio() {
    toggleAudio();
    track(EventNames.inSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.meeting,
      eventValue: isAudioEnabled ? 'off' : 'on',
      eventLabel: 'toggle_mic',
      featureVersion: 'v1',
    });
  }

  function onToggleVideo() {
    toggleVideo();
    track(EventNames.inSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.meeting,
      eventValue: isVideoEnabled ? 'off' : 'on',
      eventLabel: 'toggle_camera',
      featureVersion: 'v1',
    });
  }
  console.log('meetingstate', meetingState);
  function onToggleScreenShare() {
    if (isScreenShareSupported) {
      return () => {
        toggleScreenShare();
        track(EventNames.inSession, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.meeting,
          eventValue:
            screenShare.isLocal && screenShare?.isSharingScreen ? 'off' : 'on',
          eventLabel: 'screen_share',
          featureVersion: 'v1',
        });
      };
    }
    return false;
  }

  function handleRetryClick() {
    retry();
    track(EventNames.inSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.retry,
      eventLabel: 'retry',
      featureVersion: 'v1',
    });
  }

  const onLeaveCallClick = async () => {
    const primaryStatus = await refetchPrimaryIssueStatus();
    if (primaryStatus?.data?.notes?.length === 0) {
      setPrimaryIssueModal(true);
    } else {
      leaveCall();
    }
  };

  function onConfirmLeaveClick() {
    track(EventNames.inSession, {
      eventAction: EventActions.load,
      eventCategory: EventCategories.meetingFinish,
      eventLabel: 'meeting_finish',
      featureVersion: 'v1',
    });
    fetchCasenoteFormType({
      meetingId: meeting?.data.meetingId,
      meetingDate: meeting?.data.meetingDate,
      isInSession: false,
    });
  }

  return (
    <div className="call-wrapper">
      {primaryIssueStatusLoading && <Loader />}
      {meetingState === VideoStates.ERROR ? (
        <section className="error-section">
          <Typography size={16} color="error">
            {error as string}
          </Typography>
          <Button
            onClick={handleRetryClick}
            label="Retry"
            variant="primary"
            className="retry"
          />
        </section>
      ) : (
        <>
          <section className="call-section">
            {primaryIssueModal && (
              <PrimaryIssueModal
                meetingId={meeting?.data.meetingId || ''}
                show={primaryIssueModal}
                onClose={() => {
                  setPrimaryIssueModal(false);
                  continueRoom;
                }}
                onSave={(val) => {
                  saveNewcaseNotes({
                    meetingId: meeting?.data.meetingId,
                    userId: Number(meeting?.data?.clientId),
                    caseNotes: [
                      {
                        questionId:
                          caseNoteQuestionsData?.[
                            CaseNoteQuestionTypes.PrimaryGoal
                          ].id,
                        note: val.value,
                      },
                    ],
                  });
                }}
                primaryOptions={presentingIssueOptions}
                isFirstMeeting={isFirstMeeting}
              />
            )}

            {meetingState === VideoStates.LEAVING && (
              <LeaveRoomConfirmPopup
                onStayHereClick={continueRoom}
                onLeaveClick={onConfirmLeaveClick}
              />
            )}
            {meetingState === VideoStates.IDLE && (
              <HomeScreen
                className="start-screen"
                createCall={startCall}
                textcolor="#6b7785"
              />
            )}
            {[
              VideoStates.JOINING,
              VideoStates.CREATING,
              VideoStates.INITIALIZING,
              VideoStates.SETUPERROR,
            ].includes(meetingState) && (
              <div className="joining-call">
                <Typography size={12} color="light" textAlign="left">
                  {t('ROOM_CREATION_IN_PROGRESS')}
                </Typography>
              </div>
            )}
            {meetingState === VideoStates.HAIRCHECK && (
              <Haircheck
                setLocalParticipantDetail={setLocalParticipantDetail}
                localParticipantDetails={localParticipantDetails || {}}
                devices={devices}
                joinCall={onJoinClick}
                onBack={() => window.location.replace('/sessions')}
                useHairCheck={useHairCheck}
                className="haircheck"
              />
            )}
            <div
              style={
                meetingState === VideoStates.JOINED ||
                meetingState === VideoStates.LEAVING
                  ? { height: '100%' }
                  : {}
              }
              className={`video-wrapper ${
                screenShare?.isSharingScreen && 'screen-shared-wrapper'
              } `}
            >
              <VideoTile
                name={remoteParticipant?.name || ''}
                videoRef={screenvref}
                isNoVideo={!screenShare?.isSharingScreen}
                isJoined={screenShare?.isSharingScreen}
                isScreenShare
                meetingState={meetingState}
              />
              <div
                className={`tile-wrapper ${
                  screenShare?.isSharingScreen && 'screen-shared'
                }`}
              >
                <VideoTile
                  name={remoteParticipant?.name || ''}
                  videoRef={rvref}
                  isMuted={!remoteParticipant?.audio}
                  isNoVideo={!remoteParticipant?.video}
                  isJoined={!!remoteParticipant}
                  meetingState={meetingState}
                  waitingText="Waiting for other participant."
                  className="remote-video"
                />
                <VideoTile
                  name={localParticipantDetails?.name || ''}
                  videoRef={vref}
                  isMuted={!isAudioEnabled}
                  isNoVideo={!isVideoEnabled}
                  isLocal
                  isJoined={meetingState === VideoStates.JOINED}
                  meetingState={meetingState}
                  className="local-video"
                />
              </div>
            </div>
          </section>
          {meetingState === VideoStates.JOINED && (
            <Tray
              toggleAudio={onToggleAudio}
              toggleVideo={onToggleVideo}
              mutedAudio={isAudioEnabled}
              mutedVideo={isVideoEnabled}
              leaveCall={onLeaveCallClick}
              onChatButtonClick={() => {
                updateChatWindowSettings({
                  show: true,
                  viewMode: 'chat-view',
                  initialUser: Number(meeting.data.clientId),
                });
              }}
              toggleScreenShare={onToggleScreenShare()}
              isSharingScreen={
                screenShare.isLocal && screenShare?.isSharingScreen
              }
              className="video-tray"
            />
          )}
        </>
      )}
    </div>
  );
};

export default VideoCallRoom;
