import { beautifyUrl } from '../../../utilities/common/Path';
import Config from '../../Config';
import NetworkClient from '../../../v2/shared/service/Network';
import { PlatformCode } from '../../constant/App';

const ErrorMessages = {
  generic: 'Unable to load the meetings data, please try again!',
  inperson: 'Error while fetching inperson meeting status',
};

const client = new NetworkClient();

export const getMeetingDetails = async (
  meetingId: string,
  meetingChannel: string,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.meeting.getMeetingDetails, [meetingId]),
    { channel: Number(meetingChannel), platform: PlatformCode },
  );
  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};

export const cancelMeeting = async (
  meetingId: string,
  note: string,
  type?: string,
  otherReason?: string,
) => {
  const body = {
    options: {
      message: note,
    },
    reason: {
      type,
      otherReason,
    },
  };
  const response = await client.doPost(
    beautifyUrl(Config.api.sessions.cancelSession, [meetingId]),
    body,
  );
  if (response.error) {
    throw new Error(response.error?.message);
  }

  return response.data;
};

export const trackInPersonStatus = async (
  meetingId: string,
  meetingStatus: string,
) => {
  const response = await client.doPut(
    beautifyUrl(Config.api.inpersonSession.status, [meetingId]),
    { status: meetingStatus },
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || ErrorMessages.inperson);
};

export const fetchSingleSessionInfo = async (meetingId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.meeting.getSingleSessionDetails, [meetingId]),
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || ErrorMessages.generic);
};
